import React, {  useRef, useState } from "react";

export function SessionCard(props) {
  const { session } = props;
  const bodyRef = useRef(null);
  const [bodyFontSize] = useState(16); 

  // Dimensions in mm
  const cardHeight = 95; 
  const footerHeight = 15;
  const bodyHeight = 55; 

  const mmToPx = 3.78; 

  const footerHeightPx = footerHeight * mmToPx;
  const bodyHeightPx = bodyHeight * mmToPx;



  
  return (
    <div className="card" style={{ height: `${cardHeight}mm`, overflow: "hidden", pageBreakInside: "avoid" }}>
      <div className="card-header">
        <p className="card-title" style={{ fontSize: "16px", margin: 0 }}>
          <span className="border-end">{session.refNumber}</span> {session.title}
        </p>
      </div>
      <div className="card-body" ref={bodyRef} style={{ fontSize: `${bodyFontSize}px`, height: `${bodyHeightPx}px`, overflow: "hidden" }}>
        <div className="card-text">
          {session.shortDescription
            ?.substring(0, 500) 
            .split("%;;;%")
            .map((l, index) => (
              <p className="card-text" id={l + index} key={l + index} style={{ margin: 0 }}>
                {l}
              </p>
            ))}
        </div>
      </div>
      <div className="card-footer text-center" style={{ fontSize: "14px", padding: "5px 0", height: `${footerHeightPx}px` }}>
        <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
          <li>
            {session.presenters[1]?.firstName ? "Presenters" : "Presenter"}:{" "}
            {session.presenters[0] && session.presenters[0].firstName && session.presenters[0].lastName && (
              <span>
                {session.presenters[0].firstName} {session.presenters[0].lastName}
              </span>
            )}
            {session.presenters[1] && session.presenters[1].firstName && session.presenters[1].lastName && (
              <span>
                {" "}
                & {session.presenters[1].firstName} {session.presenters[1].lastName}
              </span>
            )}
          </li>
          <li>Duration: {session.duration}</li>
        </ul>
      </div>
    </div>
  );
}
