import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Reviews } from "./Reviews";
import { useEffect, useMemo, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { FaLaptop } from "react-icons/fa";
import { dbApi } from "../services/firebase";
import { useAuthContext } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export function SessionView() {
 const navigate = useNavigate();
 const { currentPresenter, user } = useAuthContext();
 const { id } = useParams();
 const [session, setSession] = useState(undefined);
 const [totalVotesByUser, setTotalVotesByUser] = useState(0);
 const sessionPromise = useMemo(() => dbApi.getSession(id), [id]);
 const totalVotesByUserPromise = useMemo(() => dbApi.getVotesFromPresenter(currentPresenter.id), [currentPresenter]);
 const [cardOpen, setCardOpen] = useState(true);
 const [userHasVoted, setUserHasVoted] = useState(false);
 const hasPresenterVotedOnSession = useMemo(() => dbApi.hasPresenterVotedOnSession(currentPresenter?.id, id), [currentPresenter, id]);
 const statePromise = useMemo(() => dbApi.getVotingActiveState(), []);
 const [stateVotes, setStateVotes] = useState(false);
 const [sessionPresenters, setSessionPresenters] = useState(undefined);
 const stateReviewPromise = useMemo(() => dbApi.getReviewsAndCommentsState(), []);
 const [stateReview, setStateReview] = useState(false);
 const stateXpFactorPromise = useMemo(() => dbApi.getXpFactorActiveState(), []);
 const [stateXpFactor, setStateXpFactor] = useState(false);
 const stateVoteForOwnSessionPromise = useMemo(() => dbApi.getVoteForOwnSessionState(), []);
 const [stateVoteForOwnSession, setStateVoteForOwnSession] = useState(false);
 const editActiveStatePromise = useMemo(() => dbApi.getSessionEditState(), []);
 const [editActiveState, setEditActiveState] = useState(false);
 const voteCapPromise = useMemo(() => dbApi.amountOfVotesCast(), []);
 const [voteCap, setVoteCap] = useState(0);
 const [experienceState, setExperienceState] = useState(false);

 const handleDelete = async (sessionId) => {
    try {
        await dbApi.deleteSession(sessionId);
        navigate("/sessions");
    } catch (error) {}
};

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Are you sure?</Popover.Header>
        <Popover.Body>
            There is <strong>no recovering</strong> this session.
            <Button variant="danger" onClick={() => handleDelete(id)}>
                Delete
            </Button>
        </Popover.Body>
    </Popover>
);

useEffect(() => {
    sessionPromise.then((promisedSession) => {
        dbApi.getPresentersByIds(promisedSession.presenters).then((promisedPresenters) => setSessionPresenters(promisedPresenters));
        setSession(promisedSession);
    });
}, [sessionPromise]);

useEffect(() => {
    totalVotesByUserPromise.then((totalVotes) => {
        setTotalVotesByUser(totalVotes.length);
    });
}, [totalVotesByUserPromise]);

useEffect(() => {
    hasPresenterVotedOnSession?.then((hasPresenterVotedOnSession) => setUserHasVoted(hasPresenterVotedOnSession));
}, [currentPresenter, hasPresenterVotedOnSession, id]);

useEffect(() => {
    statePromise.then((promisedState) => setStateVotes(promisedState));
}, [statePromise]);

useEffect(() => {
    stateReviewPromise.then((promisedState) => setStateReview(promisedState));
}, [stateReviewPromise]);

useEffect(() => {
    stateXpFactorPromise.then((promisedState) => setStateXpFactor(promisedState));
}, [stateXpFactorPromise]);

useEffect(() => {
    voteCapPromise.then((promisedVoteCap) => setVoteCap(promisedVoteCap));
}, [voteCapPromise]);

useEffect(() => {
    editActiveStatePromise.then((promisedEditState) => setEditActiveState(promisedEditState));
}, [editActiveStatePromise]);

useEffect(() => {
    dbApi.getExperienceState().then((promisedExperienceState) => setExperienceState(promisedExperienceState));
}, []);

useEffect(() => {
    stateVoteForOwnSessionPromise.then((promisedState) => setStateVoteForOwnSession(promisedState));
}, [stateVoteForOwnSessionPromise]);

 function TopicColor(props) {
    const {children} = props;
    if (session.topic === "eXtreme Programming") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#c7FFB1", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "Experiential") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FED28E", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "Agile Team (tools)") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FEC9DC", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "Serious Games") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#e85d54", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "Customer and Planning") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#D6F9FF", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "Case reports") {
        return <Card.Header className={"text-center"}
                            style={{backgroundColor: "#FFFF99", color: "black"}}>{children}</Card.Header>
    }
    if (session.topic === "other") {
      return <Card.Header className={"text-center"}
                          style={{backgroundColor: "#FFFFFF", color: "black"}}>{children}</Card.Header>
  }else {
        return <Card.Header className={"text-center card-header-standard"}>{children}</Card.Header>
    }
}
const canVoteOnSession = stateVotes &&
totalVotesByUser < voteCap &&
!userHasVoted &&
(stateVoteForOwnSession || !(currentPresenter.id === session.presenters[0] || currentPresenter.id === session.presenters[1]));

return (
    <Container style={{ maxWidth: "75%" }}>
        {session && (
            <Card className={"mt-3"}>
                <TopicColor>
                    <h1 className={"d-inline"}>{session?.title}</h1>

                    {currentPresenter && (((currentPresenter?.id === session?.presenters[0] || currentPresenter?.id === session?.presenters[1]) && editActiveState && !(currentPresenter.role === "reviewer")) || currentPresenter.role === "admin") && (
                        <Link to={"/sessionEdit/" + id}>
                            <Button
                                style={{
                                    right: "1vw",
                                    position: "absolute",
                                    backgroundColor: "white",
                                    borderColor: "black",
                                    color: "black",
                                }}
                            >
                                <BsFillPencilFill style={{ marginBottom: "4px" }} /> edit
                            </Button>
                        </Link>
                    )}

                    <h2>{session.subtitle}</h2>
                    {session.laptopsRequired === true && (
                        <FaLaptop
                            style={{
                                right: "1vw",
                                top: ".7em",
                                position: "absolute",
                                fontSize: "400%",
                            }}
                        />
                    )}
                    {user && (
                        <>
                            {stateVotes ? (
                                userHasVoted ? (
                                    <button
                                        onClick={() => {
                                            dbApi.withdrawVote(currentPresenter?.id, id);
                                            setUserHasVoted(false);
                                        }}
                                        className={"btn btn-light btn-outline-dark"}
                                        style={{ fontWeight: "bold", borderWidth: "2px" }}
                                    >
                                        Withdraw vote
                                    </button>
                                ) : (
                                    canVoteOnSession && (
                                        <button
                                            onClick={() => {
                                                dbApi.submitVote(currentPresenter?.id, session?.id);
                                                setUserHasVoted(true);
                                            }}
                                            className={"btn btn-light btn-outline-dark"}
                                            style={{ fontWeight: "bold", borderWidth: "2px" }}
                                        >
                                            Vote for this session
                                        </button>
                                    )
                                )
                            ) : (
                                <>{userHasVoted ? <div>You voted on this session!</div> : <></>}</>
                            )}
                        </>
                    )}
                    <hr />
                    {sessionPresenters && (
                        <div style={{ fontSize: "2rem", marginTop: "-0.5em" }}>
                            {/* First presenter */}
                            {sessionPresenters[0] && sessionPresenters[0]?.firstName && sessionPresenters[0]?.lastName && (
                                <Link to={"/profile/" + sessionPresenters[0].id} className={"sessionListLink"}>
                                    {sessionPresenters[0].firstName + " " + sessionPresenters[0].lastName}
                                </Link>
                            )}
                            {/* Second presenter */}
                            {sessionPresenters[1] && sessionPresenters[1].firstName && sessionPresenters[1].lastName && (
                                <>
                                    <div className={"d-inline"}> & </div>
                                    <Link to={"/profile/" + sessionPresenters[1].id} className={"sessionListLink"}>
                                        {sessionPresenters[1]?.firstName + " " + sessionPresenters[1]?.lastName}
                                    </Link>
                                </>
                            )}
                        </div>
                    )}
                </TopicColor>
                <Card.Body>
                    <Container>
                        <div>
                            <h5>Short description</h5>
                            {session.shortDescription ? session.shortDescription.split("%;;;%").map((i, index) => <div key={i + index}>{i}</div>) : ""}
                        </div>
                        <hr />
                    </Container>
                    <Container>
                        {session.sessionGoal && (
                            <Row>
                                <Col sm={2}>Goal of the session:</Col>
                                <Col>{session.sessionGoal}</Col>
                            </Row>
                        )}
                        {session.intendedAudience && (
                            <Row>
                                <Col sm={2}>Intended audience:</Col>
                                <Col>{session.intendedAudience}</Col>
                            </Row>
                        )}
                        {session.experienceLevel && experienceState && (
                            <Row>
                                <Col sm={2}>Expected experience:</Col>
                                <Col>{session.experienceLevel}</Col>
                            </Row>
                        )}
                        {session.sessionType && (
                            <Row>
                                <Col sm={2}>Session Type:</Col>
                                <Col>{session.sessionType}</Col>
                            </Row>
                        )}
                        {session.materialDescription && (
                            <Row>
                                <Col sm={2}> Material Description:</Col>
                                <Col>{session.materialDescription}</Col>
                            </Row>
                        )}
                        <hr />
                        <div>
                            <h5>Full description</h5>
                            {session.content ? session.content.split("%;;;%").map((i, index) => <div key={i + index}>{i}</div>) : ""}
                        </div>
                        <div>
                            <h5>Outline or timetable</h5>
                            {session.outlineOrTimetable ? session.outlineOrTimetable.split("%;;;%").map((i, index) => <div key={i + index}>{i}</div>) : ""}
                        </div>
                        <Card>
                            <Card.Header className={"text-center"}>
                                <h1 className={"d-inline"}>Extra information</h1>
                                <button onClick={() => setCardOpen(!cardOpen)} style={{ marginLeft: "1%", marginBottom: "0.7em" }} className="btn d-inline button-standard">
                                    {cardOpen ? "Close" : "Open"}
                                </button>
                            </Card.Header>
                            <Card.Body
                                style={
                                    cardOpen
                                        ? {
                                                display: "block",
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                            }
                                        : { display: "none" }
                                }
                            >
                                {stateXpFactor && session.xpFactor && (
                                    <Row>
                                        <Col sm={2}>XP factor:</Col>
                                        <Col>{session.xpFactor}</Col>
                                    </Row>
                                )}
                                {session.topic && (
                                    <Row>
                                        <Col sm={2}>Topic:</Col>
                                        <Col>{session.topic}</Col>
                                    </Row>
                                )}
                                {session.roomSetup && (
                                    <Row>
                                        <Col sm={2}>Room setup:</Col>
                                        <Col>{session.roomSetup}</Col>
                                    </Row>
                                )}
                                {session.otherLimitations && (
                                    <Row>
                                        <Col sm={2}>Other limitations:</Col>
                                        <Col>{session.otherLimitations}</Col>
                                    </Row>
                                )}
                                {session.materialsNeeded && (
                                    <Row>
                                        <Col sm={2}>Materials needed:</Col>
                                        <Col>{session.materialsNeeded}</Col>
                                    </Row>
                                )}
                                {session.materialUrl && session.state === "Confirmed" && (
                                    <Row>
                                        <Col sm={2}>Material Url:</Col>
                                        <Col>
                                            <a target="_blank" rel="noreferrer" href={session.materialUrl}>
                                                {session.materialUrl}
                                            </a>
                                        </Col>
                                    </Row>
                                )}
                            </Card.Body>
                        </Card>
                    </Container>
                </Card.Body>
                <Container style={{ margin: 10 }}>
                    {currentPresenter?.role === "admin" && (
                        <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
                            <Button variant="outline-danger">Delete</Button>
                        </OverlayTrigger>
                    )}
                </Container>
            </Card>
        )}
        {stateReviewPromise && <Reviews sessionId={id} />}{" "}
    </Container>
);
}
